body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  background: linear-gradient(to bottom, #0e0e61, #2b006d); /* Deep dark blue and purple gradient */
  color: white;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

form {
  text-align: center;
}

label {
  font-size: 1.5rem;
}

.warning {
  color: #FFD700; /* Yellow color for warning */
  font-weight: bold;
}

textarea {
  width: 100%;
  height: 150px;
  margin: 10px 0;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #19194c; /* Nice color for text box */
  color: white;
}

button {
  background-color: #4CAF50; /* Green color for button */
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 5px;
}

.donation-address {
  margin-top: 10px;
  color: white;
}

.bright-green-text2 {
  color: #FF69B4; /* Bright p
}

.bright-green-text {
  color: #4CAF50; /* Bright green color */
}

.footer-text {
  color: #4CAF50; /* Bright green color */
  margin-top: 10px;
}